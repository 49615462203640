import React, { useEffect } from "react";
import "./App.css";
import { getDailyChartForSymbol } from "./components/ApiConnector";
import Line from "./components/Line";
const App = () => {
  useEffect(() => {
    const fetchStockData = async () => {
      const result = await getDailyChartForSymbol("TSLA");

      console.log(result.data);
    };
    fetchStockData();
  }, []);
  return (
    <div className="App">
      <Line />
    </div>
  );
};
export default App;
