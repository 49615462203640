import axios from "axios";

const axiosInstance = axios.create({
  baseURL: "https://alphavantage.co/query",
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
  },
});

export const getDailyChartForSymbol = (symbol) => {
  return axiosInstance.get("", {
    params: {
      function: "TIME_SERIES_DAILY",
      symbol,
      apikey: "Z0SWDUVX6LKSXYE2",
    },
  });
};
